export const IMAGES = [
    {
        id: 1,
        src: "https://i.ibb.co/N3KtT2b/samurai.png",
        alt: "Samurai",
        filter: 'business',
        title: "Samurai",
    },
    {
        id: 2,
        src: "https://i.ibb.co/NpPHxTs/lifecell.png",
        alt: "Lifecell",
        filter: 'Business',
        title: "Lifecell",
    },
    {
        id: 3,
        src: "https://i.ibb.co/2YYywfw/galicia.png",
        alt: "Galicia",
        filter: 'Own',
        title: "GALICIA",
    },
    {
        id: 4,
        src: "https://i.ibb.co/9s6YN4C/cyberpunk.png",
        alt: "Cyberpunk",
        filter: 'Business',
        title: "Cyberpunk",
    },
    {
        id: 5,
        src: "https://i.ibb.co/TrSpf0z/chinahome.png",
        alt: "Chinahome",
        filter: 'Own',
        title: "Chinahome",
    },
    {
        id: 6,
        src: "https://i.ibb.co/mH1TddQ/chinaboy.png",
        alt: "Chinaboy",
        filter: 'Business',
        title: "Chinaboy",
    },
];
