import React from 'react';
// Import Swiper React components
import {Swiper} from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

import "./work_slider.css";

// import Swiper core and required modules
import SwiperCore, {
    Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation]);


const WorkSlider = ({children}) => {
    return (
        <>
            <Swiper navigation={true} className="mySwiper">
                {children}
            </Swiper>
        </>
    )
};

export default WorkSlider;

