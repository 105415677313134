import React from 'react';
import clients from '../styles/clients.module.css'
import ClientLogoBlock from "../ClientLogoBlock/ClientLogoBlock";
import Zoom from 'react-reveal/Zoom';
const Clients = () => {
    return (
        <div className={clients.clientsBG}>
            <h2 className={clients.duplicate}>CLIENTS</h2>
            <Zoom>
            <h2 className={clients.header}>CLIENTS</h2>
            <div className={clients.fixposition}>
                <div className={clients.selectedClientsDesc}>
                    <h3 className={clients.selectedClients}>Selected clients</h3>
                    <p className={clients.description}>We play hard so they can win fast. We build new worlds together.
                        Worlds where user value is unprecedented and where it paves the way for even more.</p>
                </div>
                <ClientLogoBlock/>
            </div>
            </Zoom>
        </div>
    );
};

export default Clients;
