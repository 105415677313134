export const teamMembers = [

    {
        id: 2,
        src: "https://i.ibb.co/M2rrdGd/Max.jpg",
        name: 'Max',
        position: 'Sound Designer'
    },
    {
        id: 3,
        src: "https://i.ibb.co/VxZXSmg/Olga.jpg",
        name: 'Olga',
        position: 'Illustrator'
    },
    {
        id: 1,
        src: "https://i.ibb.co/98qVN4H/RomanCEO.jpg",
        name: 'Roman',
        position: 'Art Director'
    },
    {
        id: 4,
        src: "https://i.ibb.co/KmGfzmD/Roman.jpg",
        name: 'Roman',
        position: 'Scriptwriter'
    },
    {
        id: 5,
        src: "https://i.ibb.co/GQ5vQrL/Alexander.jpg",
        name: 'Alexander',
        position: 'CG Generalist'
    },
    {
        id: 6,
        src: "https://i.ibb.co/xqKQcGv/Yuri.jpg",
        name: 'Yuri',
        position: 'Motion Designer'
    },
];
