import React from 'react';
import Header from "../Header/Header";
import Projects from "../Projects/Projects";
import Footer from "../Footer/Footer";

const Portfolio = () => {
    let firstP = 'The quality of work is always our top priority. It is our conviction that passion we put into '
    let secondP = 'our craft breeds the beautiful.'
    let portfolio = true
    return (
        <>
            <Header firstP={firstP} secondP={secondP}></Header>
            <Projects portfolio={portfolio}/>
            <Footer/>
        </>
    );
};

export default Portfolio;
