import header from '../styles/header.module.css'
import sheroLogo from '../images/Logo.png'
import ModalWindow from "../ModalWindow/ModalWindow";

import React, {Component} from 'react';
import NavigationLinks from "../NavigationLinks/NavigationLinks";
import {Link} from "react-router-dom";

class Navigation extends Component {
    state = {
        modalWindow: false
    }
    openModal = () => {
        window.scrollTo(0,0);
        this.setState({
            modalWindow: !this.state.modalWindow
        })
    }

    render() {
        return (
                <div className={this.state.modalWindow ? header.navigation : header.navigationFixed}>
                    <Link to="/" className={header.logoBtn}>
                        <img src={sheroLogo} alt="" className={header.sheroLogo}/>
                    </Link>
                    {window.innerWidth >= '1024' ? <NavigationLinks/> : ''}
                    {this.state.modalWindow ? <ModalWindow closeModal={this.openModal}/> :
                        <button className={header.burger} onClick={this.openModal}></button>}
                </div>
        );
    }
}

export default Navigation;

