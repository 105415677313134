import React from 'react';
import contacts from '../styles/contacts.module.css'
import vimeo from "../images/vimeo_white.png";
import dribble from "../images/dribble_white.png";
import inst from "../images/inst_white.png";
import behance from "../images/behance_white.png";
import phone from '../images/phone.png'
import mail from '../images/mail.png'
import FeedBackForm from "../FeedBackForm/FeedBackForm";
import Footer from "../Footer/Footer";
import Zoom from 'react-reveal/Zoom';

const Contacts = () => {
        return (
            <>
                <h2 className={contacts.duplicate}>CONTACT</h2>
                <Zoom>
                    <h2 className={contacts.header}>CONTACT</h2>
                    <p className={contacts.description}>Contact us without hesitation. Let’s stay in touch!</p>
                </Zoom>
                {window.innerWidth === 480 ? <div className={contacts.contactInfo}>
                    <Zoom>
                        <a href="mailto:sheroanimationinfo@gmail.com" className={contacts.mailInfo}>
                            <img src={mail} alt="" className={contacts.mailImg}/>
                            <p className={contacts.mail}>sheroanimationinfo@gmail.com</p>
                        </a>
                    </Zoom>
                    <Zoom>
                        <a href="tel:+380732151608" className={contacts.phoneInfo}>
                            <img src={phone} alt="" className={contacts.phoneImg}/>
                            <p className={contacts.phone}>+ 380 73 21 51 608</p>
                        </a>
                    </Zoom>
                    <Zoom>
                        <ul className={contacts.ul}>
                            <li><a href="https://vimeo.com/"><img src={vimeo} alt=""
                                                                  className={contacts.socialImgLinks}/></a></li>
                            <li><a href="https://dribbble.com/"><img src={dribble} alt=""
                                                                     className={contacts.socialImgLinks}/></a></li>
                            <li><a href="https://www.instagram.com/"><img src={inst} alt=""
                                                                          className={contacts.socialImgLinks}/></a></li>
                            <li><a href="https://www.behance.net/"><img src={behance} alt=""
                                                                        className={contacts.socialImgLinks}/></a></li>
                        </ul>
                    </Zoom>
                </div>
                    :
                    <div className={contacts.contactInfo}>
                        <div className={contacts.positions}>
                            <Zoom>
                                <a href="mailto:sheroanimationinfo@gmail.com" className={contacts.mailInfo}>
                                    <img src={mail} alt="" className={contacts.mailImg}/>
                                    <p className={contacts.mail}>sheroanimationinfo@gmail.com</p>
                                </a>
                            </Zoom>
                            <Zoom>
                                <a href="tel:+380732151608" className={contacts.phoneInfo}>
                                    <img src={phone} alt="" className={contacts.phoneImg}/>
                                    <p className={contacts.phone}>+ 380 73 21 51 608</p>
                                </a>
                            </Zoom>
                        </div>
                        <Zoom>
                            <ul className={contacts.ul}>
                                <li><a href="https://vimeo.com/"><img src={vimeo} alt=""
                                                                      className={contacts.socialImgLinks}/></a></li>
                                <li><a href="https://dribbble.com/"><img src={dribble} alt=""
                                                                         className={contacts.socialImgLinks}/></a></li>
                                <li><a href="https://www.instagram.com/"><img src={inst} alt=""
                                                                              className={contacts.socialImgLinks}/></a></li>
                                <li><a href="https://www.behance.net/"><img src={behance} alt=""
                                                                            className={contacts.socialImgLinks}/></a></li>
                            </ul>
                        </Zoom>
                    </div>
                }
                <FeedBackForm headers={false}/>
                <Footer/>
            </>
        );
    }
;

export default Contacts;
