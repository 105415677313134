import React from 'react';
import modal from '../styles/modal.module.css'
import header from "../styles/header.module.css";
import sheroLogo from "../images/Logo.png";
import {Link} from "react-router-dom";
import vimeo from "../images/vimeo.svg";
import dribble from "../images/dribble.svg";
import inst from "../images/inst.svg";
import behance from "../images/behance.svg";
import Slide from 'react-reveal/Slide';

const ModalWindow = ({closeModal}) => {
    return (

            <div className={modal.overflow}>
                <Slide top>
                <div className={header.navigation}>
                    <Link to="/" onClick={closeModal}>
                        <img src={sheroLogo} alt="" className={modal.sheroLogo}/>
                    </Link>
                    <button className={modal.burger} onClick={closeModal}></button>
                </div>
                <div className={modal.navigation}>
                    <ul className={modal.navigationMenu}>
                        <li>
                            <Link to="/portfolio" onClick={closeModal}>Portfolio</Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={closeModal}>About</Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={closeModal}>Contact</Link>
                        </li>
                    </ul>
                    <ul className={modal.social}>
                        <li><a href="https://vimeo.com/user122116215"><img src={vimeo} alt=""/></a></li>
                        <li><a href="https://dribbble.com/ogoshevchuk"><img src={dribble} alt=""/></a></li>
                        <li><a href="https://www.instagram.com/sheroanimation/"><img src={inst} alt=""/></a></li>
                        <li><a href="https://www.behance.net/sheroanimation"><img src={behance} alt=""/></a></li>
                    </ul>
                </div>
                </Slide>
                </div>
    );
};

export default ModalWindow;
