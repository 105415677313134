import React from 'react';
import about from '../styles/about.module.css'
import laptopMob from '../images/laptop_mob.png'
import taskMob from '../images/task_mob.png'
import workMob from '../images/work_mob.png'
import laptopPC from '../images/laptop.png'
import tasksPC from '../images/tasks.png'
import workPC from '../images/work.png'
import prodProgress from '../images/prodProgress.png'
import WorkSlider from "../Slider/WorkSlider";
import {works} from "../data/workSliderCards";
import {teamMembers} from "../data/team";
import {SwiperSlide} from "swiper/react";
import Clients from "../CLients/Clients";
import Footer from "../Footer/Footer";
import Zoom from 'react-reveal/Zoom';

const About = () => {
    return (
        <>
            <div className={about.Container}>
                <Zoom>
                    <h2 className={about.header}>ABOUT</h2>
                </Zoom>
                <h2 className={about.duplicate}>ABOUT</h2>
                <Zoom>
                    <p className={about.description}>
                        Shero is a highly qualified production studio, specializing in animation and motion design.
                    </p>
                    <p className={about.description}>
                        We are addicted to creating incredible content for clients, who grasp the importance of fine
                        design
                        that fires imagination and produces the desired effect.
                    </p>
                </Zoom>
                <Zoom>
                    <div className={about.images}>
                        {window.innerWidth === 480 ? <img src={laptopMob} alt=""/> : <img src={laptopPC} alt=""/>}
                        <div className={about.imagesSecondBlock}>
                            {window.innerWidth === 480 ? <img src={taskMob} alt=""/> : <img src={tasksPC} alt=""/>}
                            {window.innerWidth === 480 ? <img src={workMob} alt=""/> : <img src={workPC} alt=""/>}
                        </div>
                    </div>
                </Zoom>
                <Zoom>
                    <div className={about.productionSection}>
                        <h2 className={about.productionHeader}>production
                            process</h2>
                        <h2 className={about.productionDuplicate}>production
                            process</h2>
                        <div className={about.prodProgress}>
                            <img src={prodProgress} alt=""/>
                        </div>
                    </div>
                </Zoom>
                <Zoom>
                    <p className={about.sliderDesc}>
                        We are experts at creating and investing in animated content. Areas of work we are dealing with.
                    </p>
                </Zoom>
                {window.innerWidth === 480 ?
                        <WorkSlider>
                    <Zoom>
                            {works.map(el => {
                                return (
                                    <SwiperSlide>
                                        <img src={el.src} alt=""/>
                                    </SwiperSlide>
                                )
                            })}
                    </Zoom>
                        </WorkSlider>
                    :
                    <div className={about.cards}>
                        {works.map(el => {
                            return (
                                <Zoom>
                                    <img src={el.src} alt=""/>
                                </Zoom>
                            )
                        })}
                    </div>
                }
                <Zoom>
                    <h2 className={about.teamHeader}>MEET THE TEAM</h2>
                </Zoom>
                <h2 className={about.teamDuplicate}>MEET THE TEAM</h2>
                {window.innerWidth === 480 ? <WorkSlider>
                        {teamMembers.map(el => {
                            return (
                                <Zoom>
                                    <SwiperSlide>
                                        <img src={el.src} alt=""/>
                                        <p className={about.teamName}>{el.name}</p>
                                        <p className={about.teamPosition}>{el.position}</p>
                                    </SwiperSlide>
                                </Zoom>
                            )
                        })}
                    </WorkSlider>
                    :
                    <div className={about.cards}>
                        {teamMembers.map(el => {
                            return (
                                <Zoom>
                                    <div className={about.teamMemberCard}>
                                        <img src={el.src} alt=""/>
                                        <p className={about.teamName}>{el.name}</p>
                                        <p className={about.teamPosition}>{el.position}</p>
                                    </div>
                                </Zoom>
                            )
                        })}
                    </div>
                }
            </div>
            <Clients/>
            <Footer/>
        </>

    );
};

export default About;
