import React from 'react';
import Header from "../Header/Header";
import Description from "../Description/Description";
import Projects from "../Projects/Projects";
import Clients from "../CLients/Clients";
import FeedBackForm from "../FeedBackForm/FeedBackForm";
import Footer from "../Footer/Footer";
import Zoom from "react-reveal/Zoom";
import feedbackForm from "../styles/feedbackForm.module.css";

const Home = () => {
    return (
        <>
            <Header>VIEW REEL</Header>
                <Description/>
                <Projects/>
                <Clients/>
                <FeedBackForm>
                    {window.innerWidth <= 480 ?
                        <>
                            <Zoom>
                                <h2 className={feedbackForm.header}>START<br/>A NEW PROJECT</h2>
                            </Zoom>
                            <h2 className={feedbackForm.duplicate}>START<br/>A NEW PROJECT</h2>
                        </>
                        :
                        <>
                            <Zoom>
                                <h2 className={feedbackForm.header}>START A NEW PROJECT</h2>
                            </Zoom>
                            <h2 className={feedbackForm.duplicate}>START A NEW PROJECT</h2>
                        </>
                    }
                </FeedBackForm>
                <Footer/>

        </>
    );
};

export default Home;
