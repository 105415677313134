import React from 'react';
import {NavLink} from "react-router-dom";
import header from '../styles/header.module.css'

const NavigationLinks = () => {
    return (
        <ul className={header.headerNav} id='navigationLinks'>
            <li className={header.headerNavItem}>
                <NavLink to="/portfolio" activeClassName={header.selectedNav}>Portfolio</NavLink>
            </li>
            <li className={header.headerNavItem}>
                <NavLink to="/about" activeClassName={header.selectedNav}>About</NavLink>
            </li>
            <li className={header.headerNavItem}>
                <NavLink to="/contact" activeClassName={header.selectedNav}>Contact</NavLink>
            </li>
        </ul>
    );
};

export default NavigationLinks;
