import React from 'react';
import emailjs from 'emailjs-com';
import feedbackForm from '../styles/feedbackForm.module.css'
import Zoom from 'react-reveal/Zoom';

const FeedBackForm = ({children}) => {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_qq073cx', 'template_pdstc7v', e.target, 'user_RukGVxzUQ6JPVKbY3VhJi')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    return (
        <div className={feedbackForm.container}>
            {children}
            <Zoom>
                <form className={feedbackForm.contactStyleForm} onSubmit={sendEmail}>
                    <div className={feedbackForm.flexPosition}>
                        <div className={feedbackForm.flexElement}>
                            <label className={feedbackForm.label}>Name</label>
                            <input type="text" name="user_name" placeholder='Your name'
                                   className={feedbackForm.input}/>
                            <label className={feedbackForm.label}>Email</label>
                            <input type="email" name="user_phone" placeholder='Your email'
                                   className={feedbackForm.input}/>
                            <label className={feedbackForm.label}>Phone</label>
                            <input type="tel" name="user_email" placeholder='Your phone'
                                   className={feedbackForm.input}/>
                        </div>
                        <div className={feedbackForm.flexElement}>
                            <label className={feedbackForm.label}>Message</label>
                            <textarea name="message" placeholder='Your message' className={feedbackForm.textarea}/>
                        </div>
                    </div>
                    <input type="submit" value="Send Message" className={feedbackForm.submitBtn}/>
                </form>
            </Zoom>
        </div>
    );
};

export default FeedBackForm;
