import header from '../styles/header.module.css'
import portfolio from '../styles/portfolio.module.css'
import React from 'react';
import video from '../Videos/VideoBG.mp4'
import videoTablet from '../Videos/VideoBGTablet.mp4'
import videoMob from '../Videos/videoMob.mp4'
const Header = ({children, firstP, secondP}) => {
    return (
        <header className={header.headerBg}>
            {window.innerWidth === 1024 ? <div className='vidos'>
                <video autoPlay muted loop>
                    <source src={window.innerWidth === 1024 ? videoTablet : ''} type="video/mp4"/>
                </video>
            </div> : ''}
            {window.innerWidth === 1920 ? <div className='vidos'>
                <video autoPlay muted loop>
                    <source src={video} type="video/mp4"/>
                </video>
            </div> : ''}
            {window.innerWidth === 480 ? <div className='vidos'>
                <video autoPlay muted loop>
                    <source src={videoMob} type="video/mp4"/>
                </video>
            </div> : ''}
            {children ? <p className={header.headerText}>{children}</p> : ''}
            {firstP || secondP ? <div className={portfolio.text}>
                {firstP ? <p className={portfolio.first}>{firstP}</p> : ''}
                {secondP ? <p className={portfolio.second}>{secondP}</p> : ''}
            </div> : ''}
        </header>
    );
};

export default Header;


