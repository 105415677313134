export const works = [
    {
        id: 1,
        src: "https://i.ibb.co/q5fRPpp/1.gif",
    },
    {
        id: 2,
        src: "https://i.ibb.co/sVSbmn2/5.gif",
    },
    {
        id: 3,
        src: "https://i.ibb.co/tX4BCfb/4.gif",
    },
    {
        id: 4,
        src: "https://i.ibb.co/xXrGBtG/3.gif",
    },
    {
        id: 5,
        src: "https://i.ibb.co/GRzcTd8/2.gif",
    },
    {
        id: 6,
        src: "https://i.ibb.co/51GWsHX/6.gif",
    },
];
