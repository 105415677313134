import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './fonts/MonumentExtended-Regular.otf';
import {BrowserRouter} from "react-router-dom";


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App/>
    </BrowserRouter>
</React.StrictMode>,
document.getElementById('root')
);
