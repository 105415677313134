import Zoom from 'react-reveal/Zoom';
import React from 'react';
import desc from '../styles/description.module.css'
import line from '../images/Line.svg'

const Description = () => {
    return (

        <div className={desc.descriptionBG}>
            <div className={desc.decorativeLines}></div>
            <img className={desc.line} src={line} alt=""/>
            <Zoom>
                <p className={desc.first}>From the first frame to the final one, Shero provides the highest quality
                    within the scope of your budget and deadline.</p>
                <p className={desc.second}>Our ultimate mission is to help our clients breathe life into their ideas
                    that will make an indelible impression.</p>
            </Zoom>
        </div>
    );
};

export default Description;
