import Logo from '../images/LogoFooter.svg'
import footer from '../styles/footer.module.css'
import inst from '../images/inst.svg'
import behance from '../images/behance.svg'
import dribble from '../images/dribble.svg'
import vimeo from '../images/vimeo.svg'
import {Link} from "react-router-dom";
import Zoom from 'react-reveal/Zoom';
import React, {Component} from 'react';


class Footer extends Component {
    scrollTop = () => {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Zoom>
                <div className={footer.container}>
                    <Link to="/" onClick={this.scrollTop}>
                        <img src={Logo} alt="" className={footer.logo}/>
                    </Link>
                    <ul className={footer.ul}>
                        <li><Link to="/portfolio" onClick={this.scrollTop}>Portfolio</Link></li>
                        <li><Link to="/about" onClick={this.scrollTop}>About</Link></li>
                        <li><Link to="/contact" onClick={this.scrollTop}>Contact</Link></li>
                    </ul>
                    <ul className={footer.ul}>
                        <li><a href="https://vimeo.com/user122116215"><img src={vimeo} alt=""/></a></li>
                        <li><a href="https://dribbble.com/ogoshevchuk"><img src={dribble} alt=""/></a></li>
                        <li><a href="https://www.instagram.com/sheroanimation/"><img src={inst} alt=""/></a></li>
                        <li><a href="https://www.behance.net/sheroanimation"><img src={behance} alt=""/></a></li>
                    </ul>
                    <p className={footer.copy}>@ 2021 SHERO ANIMATION STUDIOS</p>
                </div>
            </Zoom>
        );
    }
}

export default Footer;

