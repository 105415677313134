import React from "react";
import {Component} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/pagination/pagination.min.css"
import "./styles.css";

// import Swiper core and required modules
import SwiperCore, {
    EffectCoverflow, Pagination
} from 'swiper/core';

import projects from "../styles/projects.module.css";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);


class ImageSlider extends Component {

    render() {
        let slides = window.innerWidth === 480 ? 1 : 3
        let {images} = this.props
        return (
            <>
                <Swiper effect={'coverflow'} onZoomChange={1.2} loop={true} initialSlide={1} grabCursor={false}
                        centeredSlides={true} coverflowEffect={{
                    "rotate": 50,
                    "stretch": 0,
                    "depth": 100,
                    "modifier": 1,
                    "slideShadows": false,
                    "sliderPerView": 3
                }} navigation={false} pagination={false} className="mySwiper" slidesPerView={slides}>
                    {images.map(el => {
                        return (
                            <>
                                <SwiperSlide key={el.id}>
                                    <a href={el.alt}>
                                        <img src={el.src} alt={el.alt}
                                             data-id={el.id} key={el.id} className={projects.swiperIMG}/>
                                    </a>
                                    <div className={projects.descProj}>
                                        {window.innerWidth === 480 ? <p className={projects.number}>{el.id <= 9 ? `0${el.id}` : `${el.id}`}</p> : ''}
                                        <p className={projects.title}>{el.title}</p>
                                    </div>
                                </SwiperSlide>
                            </>
                        )
                    })}
                </Swiper>
            </>
        )

    }
}


export default ImageSlider;


