import React from 'react';
import Adobe from '../images/Adobe.png'
import CurioInvest from '../images/CurioInvest.png'
import Galicia from '../images/Galicia.png'
import Lifecell from '../images/Lifecell.png'
import Persec from '../images/Persec.png'
import Team from '../images/Team.png'
import Zivert from '../images/Zivert.png'
import Dark from '../images/DarkLogo.png'
import clientLogoBlock from '../styles/clientLogoBlock.module.css'

const ClientLogoBlock = () => {
    return (
        <div className={clientLogoBlock.container}>
            <img src={Adobe} alt="" className={clientLogoBlock.logo}/>
            <img src={CurioInvest} alt="" className={clientLogoBlock.logo}/>
            <img src={Galicia} alt="" className={clientLogoBlock.logo}/>
            <img src={Lifecell} alt="" className={clientLogoBlock.logo}/>
            <img src={Persec} alt="" className={clientLogoBlock.logo}/>
            <img src={Team} alt="" className={clientLogoBlock.logo}/>
            <img src={Zivert} alt="" className={clientLogoBlock.logo}/>
            <img src={Galicia} alt="" className={clientLogoBlock.logo}/>
            <img src={Dark} alt="" className={clientLogoBlock.logo}/>

        </div>
    );
};

export default ClientLogoBlock;
