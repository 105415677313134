import React, {Component} from 'react';
import projects from '../styles/projects.module.css'
import ImageSlider from "../Slider/ImageSlider"

import {IMAGES} from "../data/data";
import {Link} from "react-router-dom";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

class Projects extends Component {
    state = {
        filter: 'All',
    }
    setFilter = (e) => {
        console.log(e.target.value)
        this.setState({
            filter: e.target.value
        })
    }


    render() {
        let readyImgs = this.state.filter === 'All' ? IMAGES : IMAGES.filter(el => el.filter === this.state.filter)
        let {portfolio} = this.props
        return (
            <>
                {
                    portfolio ?
                        <div className={projects.projectsBG}>
                            <div className={projects.portfolioContainer}>
                                <div className={projects.filterButtons}>
                                    <button onClick={this.setFilter} value='All'
                                            className={this.state.filter === 'All' ? projects.filterBtnActive : projects.filterBtn}>{window.innerWidth === 480 ? 'All' : 'All projects'}</button>
                                    <button onClick={this.setFilter} value='Business'
                                            className={this.state.filter === 'Business' ? projects.filterBtnActive : projects.filterBtn}>{window.innerWidth === 480 ? 'Business' : 'Business projects'}</button>
                                    <button onClick={this.setFilter} value='Own'
                                            className={this.state.filter === 'Own' ? projects.filterBtnActive : projects.filterBtn}>{window.innerWidth === 480 ? 'Own' : 'Own projects'}</button>
                                </div>
                                <div className={projects.works}>
                                    {readyImgs.map(work => {
                                        return (
                                            <>
                                                <Fade>
                                                    <div className={projects.work}>
                                                        <img src={work.src} alt=""/>
                                                        {window.innerWidth <= 480 ? <p>{work.title}</p> :
                                                            <p className={projects.hoverTitle}>{work.title}</p>}
                                                    </div>
                                                </Fade>
                                            </>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={projects.projectsBG}>
                            <h2 className={projects.duplicate}>PROJECTS</h2>
                            <Zoom>
                                <h2 className={projects.header}>PROJECTS</h2>
                            </Zoom>
                            {window.innerWidth <= 1023 ?
                                <Zoom>
                                    <ImageSlider images={IMAGES} slidesToShow={5}/>
                                </Zoom>
                                :
                                <div className={projects.workContainer}>
                                    {IMAGES.map(el => {
                                        return (
                                            <a href={el.alt}>
                                                <Zoom>
                                                    <img src={el.src} alt={el.alt}
                                                         data-id={el.id} key={el.id}
                                                         className={projects.swiperIMG}/>
                                                </Zoom>
                                            </a>
                                        )
                                    })}
                                </div>
                            }
                            <Zoom>
                                <Link to="/portfolio" className={projects.moreProjectLink}>More Projects</Link>
                            </Zoom>
                        </div>
                }
            </>
        );
    }
}

export default Projects;


