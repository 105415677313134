
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import '../components/styles/style.css'

import Navigation from "./Navigation/Navigation";
import Home from "./Home/Home";
import Portfolio from "./Portfolio/Portfolio";
import About from "./About/About";
import Contacts from "./Contacts/Contacts";
import React, {Component} from 'react';

class App extends Component {
    componentDidMount(){
        window.addEventListener('scroll', this.onScroll);
    }
    onScroll = () => {
        if(window.innerWidth >= 1024){
            if(window.scrollY >= 700 ){
                document.querySelector('#navigationLinks').classList.add('hidden_nav')
            }else{
                document.querySelector('#navigationLinks').classList.remove('hidden_nav')
            }
        }
    }
    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <Route exact path={process.env.PUBLIC_URL + '/'}>
                            <Navigation/>
                            <Home/>
                        </Route>
                        <Route path={process.env.PUBLIC_URL + '/portfolio'}>
                            <Navigation/>
                            <Portfolio/>
                        </Route>
                        <Route path={process.env.PUBLIC_URL + '/about'}>
                            <Navigation/>
                            <About/>
                        </Route>
                        <Route path={process.env.PUBLIC_URL + '/contact'}>
                            <Navigation/>
                            <Contacts/>
                        </Route>
                    </Switch>
                </Router>
            </>
        );
    }
}

export default App;

